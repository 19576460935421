import "bootstrap";
import 'animate.css';

import Swal from 'sweetalert2'


const myForm = document.getElementById('myForm');

myForm.addEventListener('submit', (e) => {
    e.preventDefault();

    Swal.fire({
        title: 'Envoye',
        icon: 'success',
        showClass: {
            popup: 'animate__animated animate__fadeInUp'
        },
        hideClass: {
            popup: 'animate__animated animate__fadeOutDown'
        }
    })

});